import { FC, useContext, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Column, Row as TableRow } from "react-table";
import { Action, M, Subjects } from "src/api/Permissions";
import {
    TeamControllerFindAllApiArg,
    TeamSummaryForUserDto,
    useTeamControllerFindAllQuery,
    useTeamControllerRemoveMutation,
} from "src/api/team.api";
import { AbilityContext } from "src/casl/Can";
import { EditButton } from "src/components/EditButton";
import ErrorBar from "src/components/ErrorBar";
import TableWithPagination from "src/components/tables/TableWithPagination";
import { Sentences } from "src/lang/lang";
import { Can } from "../../casl/Can";
import { TeamCreatorPopin } from "./team-creator-popin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmDelete from "src/components/ConfirmDelete";

export const AllTeamsPage: FC<any> = () => {
    const ability = useContext(AbilityContext);

    return (
        <Container >
            {ability.can(Action.List_All, Subjects.TeamForUser) ? (
                <AllTeams />
            ) : (
                <ErrorBar errorMessage="Sorry you're not allowed to access this page. Check your permissions with your administrator." />
            )}
        </Container>
    );
};

type RowType = TableRow<TeamSummaryForUserDto>;
export const AllTeams: FC<any> = () => {
    const [query, setQuery] = useState<TeamControllerFindAllApiArg>({
        limit: 10,
        offset: 0,
    });

    const [removeTeam, { isError: createIsError, error: createError }] =
        useTeamControllerRemoveMutation();

    const { data, isFetching, isLoading, isError, error, refetch } =
        useTeamControllerFindAllQuery(query);

    const [showCreateTeamProps, setShowCreateTeamProps] = useState(false);
    const handleCloseCreateTeamProps = () => { setShowCreateTeamProps(false); refetch(); }
    const handleShowCreateTeamProps = () => setShowCreateTeamProps(true);

    const [errorMsg, setErrorMsg] = useState("");
    const [teamToDelete, setTeamToDelete] = useState<
        TeamSummaryForUserDto | undefined
    >();
    const performDeleteTeam = async () => {
        if (teamToDelete) {
            const { teamId } = teamToDelete;
            setTeamToDelete(undefined);
            try {
                await removeTeam({ teamId }).unwrap();
            } catch (error) {
                setErrorMsg("failed to delete team");
            }
        }
    };

    const columns: Column<TeamSummaryForUserDto>[] = [
        { Header: "Team Name", accessor: "name" },
        { Header: "Visibility", accessor: "visibility" },
        { Header: "Users", accessor: "numUsers" },
        { Header: "Created at", accessor: "createdAt" },
        {
            Header: "Action",
            width: 80,
            Cell: ({ row }: { row: RowType }) => (
                <>


                    <Can
                        I={Action.Manage}
                        a={Subjects.Team}
                    >
                        <Link to={`/admin/teams/edit/${row.original.teamId}`}>
                            <EditButton />
                        </Link>
                    </Can>

                    <Can
                        I={Action.Delete}
                        a={Subjects.Team}
                    >
                        <Button variant="secondary ml-2"
                            id={`deleteUser${row.original.teamId}`}
                            onClick={() => setTeamToDelete(row.original)}

                        ><FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Can>
                </>
            ),
        },
    ];
    return (
        <>
            <div className="section mb-3">
                <Row>
                    <Col xs={'auto'}>
                        <h1>{Sentences.teamEditTitle.en}</h1>
                    </Col>
                    <Col className="text-right">
                        <Can I={Action.Create} a={Subjects.Team}>
                            <Button
                                className="w-20"
                                onClick={handleShowCreateTeamProps}>
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faPlus}
                                />{" "}New</Button>
                        </Can>

                    </Col>
                </Row>
                <div className="subtitle">
                    {Sentences.teamEditSubtitle.en}
                </div>
            </div>
            <div>
                <ConfirmDelete
                    showConfirmation={teamToDelete !== undefined}
                    onCancelDelete={() => setTeamToDelete(undefined)}
                    onPerformDelete={performDeleteTeam}
                />
                <ErrorBar errorMessage={isError ? JSON.stringify(error) : ""} />
                <TableWithPagination
                    columns={columns}
                    data={data?.results || []}
                    fetchData={(pageIndex, pageSize) => {
                        const { limit, offset } = query;
                        const l = pageSize;
                        const o = pageSize * pageIndex;
                        if (limit !== l || offset !== o) {
                            setQuery({ limit: l, offset: o });
                        }
                    }}
                    itemCount={data?.total || 0}
                    loading={isLoading}
                /></div>
            <TeamCreatorPopin show={showCreateTeamProps} handleClose={handleCloseCreateTeamProps} />
        </>
    );
};
